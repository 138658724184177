import React, { useState, useEffect } from "react";
import "../../assets/css/common.css";
import { t } from '../../components/i18n/i18n';
import {
    randomImageEndpoint,
    randomImageFolderAssets
} from '../../assets/data/constants';

export const HotNews = () => {
    const [photo, setPhoto] = useState(null);

    useEffect(() => {
        // Fetch the random image URL from the server
        const fetchImage = async () => {
            const mediaUrl = `${randomImageEndpoint}?folder=asset`;
            try {
                const response = await fetch(mediaUrl);
                if (response.ok) {
                    const fileName = await response.text();
                    setPhoto(`${randomImageFolderAssets}${fileName}`);
                } else {
                    console.error("Failed to fetch image");
                }
            } catch (error) {
                console.error("Error fetching the image:", error);
            }
        };

        fetchImage();
    }, []);

    return (
        <>
            <section className='text_container_light'>
                <div className='container_100'>
                    <div className='container boxItems'>
                        <p className='news_text_shadow'>{t("hotnews_basic_1")}</p><br />
                        {photo ? (
                            <img width='100%' src={photo} alt='Hot News' />
                        ) : (
                            <p>Loading image...</p>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};
