import React from "react"
import { Footer } from "./components/footer/Footer"
import { Header } from "./components/header/Header"
import { Login } from "./pages/login/Login"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { Account } from "./pages/account/Account"
import { WebArchive } from "./pages/archive/WebArchive"
import { Create } from "./components/create/Create"
import { Contact } from "./pages/common/Contact"
import { Schedule } from "./pages/common/Schedule"
import { About } from "./pages/common/About"
import { HotNews } from "./pages/common/HotNews"
import { Help } from "./pages/common/Help"
import { home_folder, oldChurchSite } from "./assets/data/constants"
import {append} from "./components/i18n/i18n";
import YoutubeNews from "./components/blog/YoutubeNews";
import InstaFeed from "./components/blog/InstaFeed";

const App = () => {

  let locale = localStorage.getItem('userLanguage');
  if (!locale) {
    locale = 'ua';
  }
  append(require(`./localization/${locale}.json`));

  return (
    <>
      <Router basename={`${home_folder}`}>
        <Header />
        <Routes>
          <Route exact path='/' element={<HotNews/>} />
          <Route path='/news/:id' element={<YoutubeNews/> }  />
          <Route exact path='/login' element={<Login/>} />
          <Route exact path='/insta' element={<InstaFeed/>} />
          <Route exact path='/account' element={<Account/>} />
          <Route exact path='/create' element={<Create/>} />
          <Route exact path='/contact' element={<Contact/>} />
          <Route exact path='/about' element={<About/>} />
          <Route exact path='/help' element={<Help/>} />
          <Route exact path='/archive' element={<WebArchive/>} />
          <Route exact path='/schedule' element={<Schedule/>} />
          <Route exact path='/oldsite' element={() => {
            window.location.href = oldChurchSite;
            return null;
          }} />
        </Routes>
        <Footer />
      </Router>
    </>
  )
};
export default App
